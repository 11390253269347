import { Component } from "react";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { API_URL } from "../constants";

class RequestForm extends Component {
    state = {
        email: '',
        text: '',
        id: this.props.data,
        name: '',
        form: 'block',
        message: 'none'
    };

    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleSubmit = e => {
        e.preventDefault();

        const { name, email, text, id } = this.state;

        const data = {
            name,
            email,
            text,
            id
        };
        axios
            .post(API_URL + 'email/', data, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
            .then(() => {
                this.setState({
                    form: 'none',
                    message: 'block'
                })
            })
            .catch(err => {
                console.error(err);
            });
    };
    render() {
        return (
            <div>
                <Form style={{display: this.state.form}} onSubmit={this.handleSubmit}>
                    <Form.Group controlId="nameForm">
                        <Form.Label>Your name</Form.Label>
                        <Form.Control type="text" name="name" placeholder="Jane Doe" onChange={this.handleInputChange} />
                    </Form.Group>
                    <Form.Group controlId="emailForm">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" name="email" placeholder="name@example.com" onChange={this.handleInputChange} />
                    </Form.Group>
                    <Form.Group controlId="messageForm">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" name="text" rows={3} onChange={this.handleInputChange} />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">
                        Submit
                    </Button>
                </Form>
                <div className="message" style={{display: this.state.message}}>
                    <p>Thank you for your interest!</p>
                    <p>We've sent your request to the volunteer.</p>
                </div>
            </div>
        );
    }
}


export default RequestForm;