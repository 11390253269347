import React, { useState, useEffect, Fragment } from 'react';
import { useParams, Link } from "react-router-dom";
import { withRouter } from "react-router";
import axios from "axios";
import { API_URL } from "../constants";
import RequestForm from "./RequestForm";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import parse from 'html-react-parser';

const Animal = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [animal, setAnimal] = useState('');
  const [animals, setAnimals] = useState('');
  const [org, setOrg] = useState('');
  const [fav, setFav] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchData = async () => {
      const animalData = await axios.get(API_URL + 'animals/' + id + '/');
      if (localStorage.getItem('token') !== null) {
        const favId = await axios.get(API_URL + 'v1/users/user-fav-id/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          }
        });
        if (favId.data.includes(Number(id))) {
          setFav(true);
        }
      }
      const orgData = await axios.get(API_URL + 'v1/orgs/org/' + animalData.data.org + '/', {
        params: {
          id: id,
        }
      });
      setOrg(orgData.data.org);
      setAnimals(orgData.data.animals);
      setAnimal(animalData.data);
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const addToFav = () => {
    axios.post(API_URL + 'v1/users/add-to-fav/', animal.id, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(setFav(true));
  };

  const removeFromFav = () => {
    axios.post(API_URL + 'v1/users/remove-from-fav/', animal.id, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(setFav(false));
  };

  let gallery;
  if (loading === false) {
    if (animal.photos.length) {
      gallery = <Carousel>
        {animal.photos.map((photo) => {
          return (
            <div className="d-flex m-auto">
              <img key={photo.id} alt="" src={photo.photo} />
            </div>
          )
        })}
      </Carousel>
    }
    else {
      gallery = '';
    }
  }
  let transfer;
  if (animal.transfer === false) {
    transfer = <p>{animal.name} is looking for home in {animal.country}</p>
  }
  else {
    transfer = <p>{animal.name} is ready to travel abroad</p>
  }
  let logo;
  let style;
  if (org.image !== null) {
    style = "mt-5";
    logo = <div className="org-logo"><img src={org.image} alt="" /></div>
  }
  else {
    style = '';
    logo = '';
  }

  let modal;
  let social;
  if (localStorage.getItem('token') !== null) {
    if (fav === true) {
      social =
        <div className="s-buttons">
          <div className="btn btn-white mb-3" onClick={removeFromFav}><i className="fas fa-heart"></i>Remove from Favorive</div>
          <div className="btn btn-outline mb-3" onClick={handleShow}><i className="fal fa-envelope"></i>Send request</div>
        </div>;
    }
    else {
      social =
        <div className="s-buttons">
          <div className="btn btn-white mb-3" onClick={addToFav}><i className="fas fa-heart"></i>Add to Favorive</div>
          <div className="btn btn-outline mb-3" onClick={handleShow}><i className="fal fa-envelope"></i>Send request</div>
        </div>
    }
    modal = <RequestForm data={animal.id} />;
  }
  else {
    social =
      <div className="s-buttons">
        <div className="btn btn-white mb-3" onClick={handleShow}><i className="fas fa-heart"></i>Add to Favorive</div>
        <div className="btn btn-outline mb-3" onClick={handleShow}><i className="fal fa-envelope"></i>Send request</div>
      </div>;
    modal =
      <div className="attention">
        <p>To send request or add {animal.name} to your Favorite List please log in or sign up</p>
        <div className="row pt-3">
          <div className="col">
            <a href="/login/">Log In</a>
          </div>
          <div className="col">
            <a href="/signup/">Sign Up</a>
          </div>
        </div>
      </div>;
  }

  return (
    <div id="content" className="container mt-5">
      <div className="row">
        {loading === false && (
          <Fragment>
            <div className="col-12 col-lg-8">
              <div className="bg-white p-3 rounded shadow-sm">
                <div className="row">
                  <div className="col-12 col-md-8">
                    {gallery}
                  </div>
                  <div className="col-12 col-md-4">
                    <h1>{animal.name}</h1>
                    <p>Age: {animal.age}</p>
                    <p>Sex: {animal.gender}</p>
                    <p>Health status: {animal.health}</p>
                    <p>From {animal.city}, {animal.country}</p>
                    {transfer}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h3>Meet {animal.name}</h3>
                    {parse(animal.description)}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="row mt-3 mt-lg-0">
                <div className="col-12 col-md-6 col-lg-12 pr-3 pr-md-2 pr-lg-3">
                  <div className="bg-blue mb-5 p-3 rounded request-form shadow-sm">
                    <h4 className="mb-3">Think you and {animal.name} might be a match?</h4>
                    {social}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-12 pl-3 pl-md-2 pl-lg-3">
                  {org !== '' && (
                    <div className="bg-white p-3 rounded about-org shadow-sm">
                      {logo}
                      <h4 className={style}>{org.name}</h4>
                      <p>{org.country}, {org.city}</p>
                      <p className="org-desc">{parse(org.description)}</p>
                      {org.fb !== '' && (
                        <a className="btn btn-blue-outline mb-3" href={org.fb} target="_blank" rel="noreferrer"><i class="fab fa-facebook"></i>Follow us</a>
                      )}
                      {org.site !== '' && (
                        <a className="btn btn-blue-outline mb-3" href={org.site} target="_blank" rel="noreferrer">Visit our site</a>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Modal show={show} onHide={handleClose} centered className="modal-block">
              <Modal.Body>{modal}</Modal.Body>
            </Modal>
          </Fragment>
        )}
      </div >
      {
        animals.length !== 0 && (
          <div className="row">
            <div className="col-12">
              <h2>Meet more pets</h2>
            </div>
            {animals.map((animal) => {
              if (animal.photos.length) {
                var photo = animal.photos[0].photo;
              }
              else {
                if (animal.species === 'cat') {
                  photo = '/static/assets/img/cat_color.png';
                }
                else if (animal.species === 'dog') {
                  photo = '/static/assets/img/dog_color.png';
                }
                else if (animal.species === 'other') {
                  photo = '/static/assets/img/fish_color.png';
                }
              }

              return (
                <div key={animal.id} className="col-12 col-md-4 mb-3 d-flex align-items-stretch">
                  <Link key={animal.id} to={"/animals/" + animal.id + "/"}>
                    <Card key={animal.id} className="h-100">
                      <Card.Img variant="top" src={photo} />
                      <Card.Body>
                        <Card.Title>{animal.name}</Card.Title>
                        <Card.Text>
                          {animal.gender}, {animal.age}
                        </Card.Text>
                        <Card.Text>
                          {animal.city}, {animal.country}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
              );
            })}
          </div>
        )
      }
    </div >

  )
}


export default withRouter(Animal);