import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import { API_URL, URL } from "../constants";

const Navigation = () => {
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      setIsAuth(true);
    }
  }, []);

  const handleLogout = e => {
    e.preventDefault();

    fetch(API_URL + 'v1/users/auth/logout/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        localStorage.clear();
        window.location.replace(URL);
      });
  };

  return (
      <div>
        {isAuth === true ? (
          <Fragment>
            {' '}
            <span className="text-main mr-3">
              <Link to='/dashboard/'>Dashboard</Link>
            </span>
            <span className="text-main">
              <Link onClick={handleLogout}>Log Out</Link>
            </span>
          </Fragment>
        ) : (
          <Fragment>
            {' '}
            <span className="text-main mr-3">
              <Link to='/login/'>Log In</Link>
            </span>
            <span className="text-main">
              <Link to='/signup/'>Sign Up</Link>
            </span>
          </Fragment>
        )}
      </div>
    
  );
};

export default withRouter(Navigation);