import React, { useState, useEffect } from 'react';
import { API_URL, URL } from "../../constants";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const Reset = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState('block');
    const [after, setAfter] = useState('none');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            window.location.replace(URL + 'dashboard/');
        } else {
            setLoading(false);
        }
    }, []);

    const onSubmit = e => {
        e.preventDefault();

        const user = {
            email: email,
        };

        fetch(API_URL + 'v1/users/auth/password/reset/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        })
            .then(res => res.json())
            .then(data => {
                setMessage(data.detail);
                setForm('none');
                setAfter('block');
            });
    };

    return (
        <div id="content" className="container mt-5">
            <div className="row">
                <div className="col-12 col-lg-5 col-sm-6 mx-auto bg-white rounded p-3 shadow-sm">
                    {loading === false && <h2>Password reset</h2>}
                    {loading === false && (
                        <Form style={{display: form}} onSubmit={onSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email"
                                    name="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    required
                                    placeholder="Enter email" />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="w-100">
                                Reset
                            </Button>
                        </Form>
                    )}
                    <div style={{display: after}}>
                        <p className="text-center">{message}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reset;