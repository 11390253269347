import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { API_URL } from "../../constants";
import axios from "axios";

const DashboardMenu = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState('');

  useEffect(() => {
      if (localStorage.getItem('token') === null) {
          window.location.replace('https://adoptpet.eu/login/');
      } else {
          axios.get(API_URL + 'v1/users/user-detail/', {headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
        }})
        .then(data => {
          setUser(data.data);
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
        });             
      }
  }, []);
  
  return (
    <div>
      <ul>
        {loading === false && (
          <Fragment>
            {user.type === 'org' && (
              <Fragment>
                <li><Link to={'/dashboard/animals/'}>Our animals</Link></li>
                <li><Link to={'/dashboard/requests/'}>Requests</Link></li>
                <li><Link to={'/dashboard/fav/'}>Favorites</Link></li>
                <li><Link to={'/dashboard/orginfo/'}>Edit org info</Link></li>
              </Fragment>
            )}
            {user.type === 'user' && (
              <Fragment>
                <li><Link to={'/dashboard/fav/'}>Favorites</Link></li>
                <li><Link to={'/dashboard/requests/'}>Requests</Link></li>
                <li><Link to={'/dashboard/userinfo/'}>Edit my info</Link></li>
              </Fragment>
            )}
          </Fragment>
        )}
      </ul> 
      <p className="text-small">For any technical problems please contact <a href="mailto:tech@adoptpet.eu">tech@adoptpet.eu</a></p>
    </div>   
  );
}

export default withRouter(DashboardMenu);

