import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from "react-router";
import DashboardMenu from "./DashboardMenu";
import { URL } from "../../constants";

const Dashboard = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            window.location.replace(URL + 'login/');
        } else {
            setLoading(false);
        }
    }, []);
    
    return (
        <div id="content" className="container mt-5">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="m-1 bg-white p-3 rounded shadow-sm">
                        <DashboardMenu />
                    </div>
                </div>
                <div className="col-12 col-md-8">
                    <div className="m-1 bg-white p-3 rounded shadow-sm">
                        {loading === false && (
                            <Fragment>
                                <h1>Dashboard</h1>
                                <p>If you have any technical problems, please let us know about them at <a href="mailto:tech@adoptpets.eu">tech@adoptpets.eu</a></p>
                                <p>For any other question, please contact <a href="mailto:info@adoptpets.eu">info@adoptpets.eu</a></p>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Dashboard);