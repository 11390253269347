import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";
import DashboardMenu from "./DashboardMenu"
import { API_URL, URL } from "../../constants";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';


const AnimalAddEdit = (props) => {
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({
        name:'',
        status: '',
        species: '',
        age: '',
        health: '',
        gender: '',
        description: '',
        city: '',
        country: '',
        photos: [],
        transfer: false,
    });
    let type = props.type;
    const [cities, setCity] = useState('');
    const [countries, setCountry] = useState('');
    const [show, setShow] = useState(false);
    const [geotype, setGeotype] = useState('');
    const [saved, setSaved] = useState('none');
    const [left, setLeft] = useState('');
    
    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            window.location.replace(URL + 'login/');
        } else {
            if (type === 'edit') {
                const fetchData = async () => {
                    const data = await axios.get(API_URL + 'animals/edit/' + id + '/');
                    const userDetail = await axios.get(API_URL + 'v1/users/user-detail/', {
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${localStorage.getItem('token')}`
                      }
                    });
                    const countryList = await axios.get(API_URL + 'countrylist/');
                    if (data.data.country) {
                        const cityList = await axios.get(API_URL + 'citylist/' + data.data.country +'/');
                        setCity(cityList.data)
                    }
                    if (userDetail.data.id === data.data.org) {
                        setState({
                            ...state,
                            name: data.data.name,
                            status: data.data.status,
                            species: data.data.species,
                            age: data.data.age,
                            health: data.data.health,
                            gender: data.data.gender,
                            description: data.data.description,
                            city: data.data.city,
                            country: data.data.country,
                            photos: data.data.photos,
                            transfer: data.data.transfer
                        });
                        setCountry(countryList.data);
                        setLoading(false);
                    }
                    if (data.data.photos.length < 6) {
                        var l = 6 - data.data.photos.length
                        setLeft(l);
                    }  
                    else {
                        setLeft(0);
                    }                 
                  };
                fetchData();
            }
            if (type === 'create') {
                axios.get(API_URL + 'countrylist/')
                .then(data => {
                    setCountry(data.data); 
                    setLoading(false);
                    setLeft(6);
                });
            }
        }
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = (gt) => {
        setGeotype({
            ...geotype,
            type: gt,
            id: 'new-' + gt,
        });
        setShow(true);
    }

    const handleInputNewChange = e => {
        setGeotype({
            ...geotype,
            value: e.target.value,
        });
    }
    const handleNewSubmit = e => {
        e.preventDefault();
        if (geotype.type === 'country') {
            const data = {
                country: geotype.value
            };
            axios.post(API_URL + 'country/', data )
            .then(data => {
                setCountry(data.data);
                setState({
                    ...state,
                    country: data.data[data.data.length - 1].id,
                });
                setShow(false);
                setGeotype('');
            })
                .catch(err => {
                    console.error(err);
                });
        }
        if (geotype.type === 'city') { 
            const data = {
                country: state.country,
                city: geotype.value
            };
            axios.post(API_URL + 'city/', data )
            .then(data => {
                setCity(data.data);
                setState({
                    ...state,
                    city: data.data[data.data.length - 1].id,
                });
                setShow(false);
                setGeotype('');
            })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    const handleRemoveImage = (id) => {
        let imgs = [];
        state.photos.map((img) => {
            return imgs.push(img.id);
        })
        axios.delete(API_URL + 'remove-images/' + id + '/');
        var index = imgs.indexOf(id);
        setState({
            ...state,
            photos: state.photos.filter((_, i) => i !== index),
        });
        var l = left + 1;
        setLeft(l);
    }

    const handleInputUpload = e => {
        let fd = new FormData();
        let obj = [];
        let arr  = Array.from(e.target.files);
        if (arr.length > left) {
            arr = arr.slice(0, left);
        }
        arr.map(f => obj.push(f));
        obj.forEach((photo) => fd.append('photos', photo));
        axios.post(API_URL + 'images/', fd, 
            {headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Token ${localStorage.getItem('token')}`
            }})
            .then(data => {
                let photos = state.photos;
                data.data.map(f => photos.push(f));
                setState({
                    ...state,
                    photos: photos,
                });
                var l = 6 - state.photos.length
                setLeft(l);
            })
            .catch(err => {
                console.error(err);
            });
    }
    const handleInputChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === 'country') {
            var id = e.target.value;
            axios.get(API_URL + 'citylist/' + id + '/')
                .then(data => {
                    setCity(data.data);
                })
                .catch(err => {
                    console.error(err);
                });
        }   
    };

    const handleSubmit = e => {
        e.preventDefault();
        let images = state.photos;
        let photos = [];
        images.map((image, i) => {
            i = {
                'photo': image.photo,
                'id': image.id,
                'priority': i,
                'animal': ''
            }
            photos.push(i);
        });
        setState({
            ...state,
            photos: photos,
        });
        const {name, status, species, age, health, gender, description, city, country, transfer} = state;
        const data = {
            name,
            status,
            species,
            age, 
            health, 
            gender,
            description, 
            city, 
            country,
            photos,
            transfer,
            id
        };
        if (type === 'edit') {
            axios.put(API_URL + 'animals/' + id + '/', data)
                .then(() => {
                    setSaved('inline-block');
                    setTimeout(function() {
                        setSaved('none');
                    }, 1000);
                    window.location.replace(URL + 'dashboard/animals/');
                })
                .catch(err => {
                    console.error(err);
                });
        };
        if (type === 'create') {
            axios.post(API_URL + 'animals/', data, 
            {headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
            }})
            .then(() => {
                setSaved('inline-block');
                setTimeout(function() {
                    setSaved('none');
                }, 1000);
                window.location.replace(URL + 'dashboard/animals/');
            })
                .catch(err => {
                    console.error(err);
                });
        }
    };
    let citylist;
    if (cities) {
        citylist = cities.map((city) => {
            return (
                <option key={city.id} value={city.id}>{city.city}</option>
            );
        })
    }
    else {
        citylist = '';
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        setState(({photos}) => ({
            ...state,
            photos: arrayMove(photos, oldIndex, newIndex),
        }));
    };
    let photolist;
    if (state.photos.length) {
        const SortableItem = SortableElement(({value, id}) => {
            var url = URL + value;
            return(
                <div className="position-relative my-1 col-12 col-md-4">
                    <div className="remove-image" onClick={() => handleRemoveImage(id)}><i class="far fa-times-circle"></i></div>
                    <div className="img-preview" style={{background: 'url(' + url + ') center no-repeat', backgroundSize: 'contain'}}></div>
                </div>   
            )
        });
        const SortableList = SortableContainer(({photos}) => {
            return (
                <div className="row mx-1">
                    {photos.map((value, index) => (
                    <SortableItem key={`item-${value.id}`} index={index} value={value.photo} id={value.id}/>
                    ))}
                </div>
            );
        });
        photolist = <SortableList photos={state.photos} onSortEnd={onSortEnd} axis={'xy'} distance={1}/>
    }
    else {
        photolist = '';
    }
    let status;
    if (state.country) {
        status = <Button variant="primary" onClick={() => handleShow('city')}>Add new</Button>;
    }
    else {
        status = <Button variant="primary" disabled>Add new</Button>;
    }

    return (
        <div id="content" className="container mt-5">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="m-1 bg-white p-3 rounded shadow-sm">
                        <DashboardMenu />
                    </div>
                </div>
                <div className="col-12 col-md-8">
                    <div className="m-1 bg-white p-3 rounded shadow-sm">
                        {loading === false && (
                            <Fragment>
                                <h1>Dashboard</h1>
                                <Form onSubmit={handleSubmit}>
                                    <form action="" className="myForm" id="photos">
                                    <Form.Group>
                                        <Form.File id="photos" label="Animal images" name="photos"
                                            type="file"
                                            accept="image/*"
                                            multiple
                                            onChange={handleInputUpload}/>
                                        <Form.Text className="text-muted">
                                            Allowed 6 photos max. Drag images to order it
                                        </Form.Text>
                                    </Form.Group>
                                    {photolist}
                                    </form>
                                    <Form.Group controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" name="name" value={state.name} onChange={handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group controlId="status">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control as="select" onChange={handleInputChange} name="status" value={state.status}>
                                            <option>--Select--</option>
                                            <option value="active">Active</option>
                                            <option value="process">In process</option>
                                            <option value="adopted">Adopted</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="species">
                                        <Form.Label>Animal species</Form.Label>
                                        <Form.Control as="select" onChange={handleInputChange} name="species" value={state.species}>
                                            <option>--Select--</option>
                                            <option value="cat">Cat</option>
                                            <option value="dog">Dog</option>
                                            <option value="other">Other</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="gender">
                                        <Form.Label>Animal gender</Form.Label>
                                        <Form.Control as="select" onChange={handleInputChange} name="gender" value={state.gender}>
                                            <option>--Select--</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="age">
                                        <Form.Label>Age</Form.Label>
                                        <Form.Control as="select" onChange={handleInputChange} name="age" value={state.age}>
                                            <option>--Select--</option>
                                            <option value="kid">Less than 1 y.o.</option>
                                            <option value="adult">1 - 7 y.o.</option>
                                            <option value="senior">More than 7 y.o.</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="health">
                                        <Form.Label>Health</Form.Label>
                                        <Form.Control as="select" onChange={handleInputChange} name="health" value={state.health}>
                                            <option>--Select--</option>
                                            <option value="healthy">Healthy</option>
                                            <option value="special">Special needs</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="country">
                                        <Form.Label>Country</Form.Label>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Control inline as="select" onChange={handleInputChange} name="country" value={state.country}>
                                                    <option value="0">--Select--</option>
                                                    {countries.map((country) => {
                                                        return (
                                                            <option key={country.id} value={country.id}>{country.country}</option>
                                                        );
                                                    })}
                                                </Form.Control>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Button inline variant="primary" onClick={() => handleShow('country')}>Add new</Button>
                                            </Col>
                                        </Row>
                                        <Form.Text className="text-muted">
                                            If your country isn't in a list, press 'Add new'
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="city">
                                        <Form.Label>City</Form.Label>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Control as="select" onChange={handleInputChange} name="city" value={state.city}>
                                                    <option>--Select country first--</option>
                                                    {citylist} 
                                                </Form.Control>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                {status}
                                            </Col>
                                        </Row>
                                        <Form.Text className="text-muted">
                                            If your city isn't in a list, press 'Add new'
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="description">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control as="textarea" rows={6} value={state.description} onChange={handleInputChange} name="description"/>
                                        <Form.Text className="text-muted">
                                            Tell more about your pet: its behaviour, habits, health (if it's vaccinated / neutered ) and so on
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="transfer">
                                        <Form.Check type="checkbox" defaultChecked={state.transfer} onChange={() => setState({...state, transfer: !state.transfer})}label="We are ready to send animal abroad" />
                                    </Form.Group>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                    <div id="save-block" style={{display: saved}} className="save-block"><i class="fal fa-check"></i>Saved!</div>
                                </Form>
                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                    <Modal.Title>Add new {geotype.type}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form  onSubmit={handleNewSubmit}>
                                            <Form.Group controlId={geotype.id}>
                                                <Form.Control type="text" name={geotype.id} value={geotype.value} onChange={handleInputNewChange}/>
                                            </Form.Group>
                                            <Button variant="primary" type="submit">
                                                Submit
                                            </Button>
                                        </Form>
                                    </Modal.Body>
                                </Modal>
                            </Fragment>
                        )}                     
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(AnimalAddEdit);