import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from "react-router";
import Table from 'react-bootstrap/Table';
import DashboardMenu from "./DashboardMenu"
import { Link } from "react-router-dom";
import { API_URL } from "../../constants";
import axios from "axios";
import Button from 'react-bootstrap/Button';


const Dashboard = () => {
    const [animals, setAnimals] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            window.location.replace('https://adoptpets.eu/login/');
        } else {
            fetch(API_URL + 'org_animals_list/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
                .then(res => res.json())
                .then(data => {
                    setAnimals(data);
                    setLoading(false);
                });
        }
    }, []);
    const handleDelete = id => {
        axios.delete(API_URL + 'animals/' + id + '/')
                .then(() => console.log('Request sent'))
                .catch(err => {
                    console.error(err);
                });
    }
    return (
        <div id="content" className="container mt-5">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="m-1 bg-white p-3">
                        <DashboardMenu />
                    </div>
                </div>
                <div className="col-12 col-md-8">
                    <div className="m-1 bg-white p-3">
                        {loading === false && (
                            <Fragment>
                                <h1>Dashboard</h1>
                                <Link to={"/dashboard/create/"}>Add new</Link>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Username</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {animals.map((animal) => {
                                            return (
                                                <tr key={animal.id}>
                                                    <td>{animal.id}</td>
                                                    <td>{animal.name}</td>
                                                    <td>{animal.status}</td>
                                                    <td>{animal.species}</td>
                                                    <td><Link key={animal.id} to={"/dashboard/animals/" + animal.id + "/edit/"}>Edit</Link></td>
                                                    <td><Button variant="danger" size="sm" onClick={() => handleDelete(animal.id)}>Delete</Button></td>
                                                </tr> 
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Dashboard);