import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { API_URL, URL } from "../../constants";
import axios from "axios";

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [type, setType] = useState('user');
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState('block');
  const [after, setAfter] = useState('none');
  const [policy, setPolicy] = useState(false);


  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      window.location.replace(URL + 'dashboard');
    } else {
      setLoading(false);
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    const user = {
      email: email,
      password1: password1,
      password2: password2,
      type: type,
    };
    if (policy === true) {
      await axios.post(API_URL + 'v1/users/auth/register/', user)
        .then(data => {
          setErrors(false);
          setForm('none');
          setAfter('block');
        })
        .catch(err => {
          console.error(err);
          setEmail('');
          setPassword1('');
          setPassword2('');
          setType('');
          localStorage.clear();
          setErrors(true);
        });
    }
  };

  return (
    <div id="content" className="container mt-5">
      <div className="row">
        <div className="col-12 col-lg-5 col-sm-8 mx-auto bg-white rounded p-3 shadow-sm">
          {loading === false && <h2 style={{ display: form }}>Sign up</h2>}
          {errors === true && <h3>Cannot signup with provided credentials</h3>}
          <Form style={{ display: form }} onSubmit={onSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
                placeholder="Enter email" />
            </Form.Group>
            <Form.Group controlId="formBasicPassword1">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password"
                name="password1"
                value={password1}
                onChange={e => setPassword1(e.target.value)}
                required
                placeholder="Password" />
            </Form.Group>
            <Form.Text className="text-muted">
              Password should contains at least 8 symbols, letters and numbers
            </Form.Text>
            <Form.Group controlId="formBasicPassword2">
              <Form.Label>Confirm password</Form.Label>
              <Form.Control type="password"
                name="password2"
                value={password2}
                onChange={e => setPassword2(e.target.value)}
                required
                placeholder="Confirm password" />
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check inline type="radio" name="type" label="I want to adopt an animal" value="user" onChange={e => setType('user')} />{' '}
              <Form.Check inline type="radio" name="type" label="I would like to find owners for pets" value="org" onChange={e => setType('org')} />{' '}
            </Form.Group>
            <Form.Group controlId="formAccept">
              <div className="form-check form-check-inline">
                <Form.Check.Input defaultChecked={policy} type="checkbox" onChange={() => setPolicy(!policy)} />
                <Form.Check.Label>I have read and agree to the <a href="/policy/">policy</a></Form.Check.Label>
              </div>
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              Sign up
            </Button>
          </Form>
          <div style={{ display: after }}>
            <h3 className="text-center">Thank you for registration!</h3>
            <p className="text-center">We've sent you an email with confirmation link</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;