import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from "react-router";
import DashboardMenu from "./DashboardMenu"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { API_URL, URL } from "../../constants";
import axios from "axios";


const UserInfo = () => {
    const [user, setUser] = useState('');
    const [loading, setLoading] = useState(true);
    const [countries, setCountries] = useState('');
    const [saved, setSaved] = useState('none');

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            window.location.replace(URL + 'login/');
        } else {
            const fetchData = async () => {
                const userData = await axios.get(API_URL + 'v1/users/user-detail/', {headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                }});
                
                const countryList = await axios.get(API_URL + 'countrylist/');
                
                setUser(userData.data);
                setCountries(countryList.data);
                setLoading(false);
            };
            fetchData();  
        }
    }, []);
    const handleInputChange = e => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
        console.log(user);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const {id, email, type, first_name, last_name, country} = user;
        const data = {
            id,
            email,
            type,
            first_name,
            last_name,
            country
        };
        
        axios.put(API_URL + 'v1/users/user-detail/', data, {headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          }})
            .then(() => {
                setSaved('inline-block');
                setTimeout(function() {
                    setSaved('none');
                }, 1000);
            })
            .catch(err => {
                console.error(err);
            });
    };
    return (
        <div id="content" className="container mt-5">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="m-1 bg-white p-3 rounded shadow-sm">
                        <DashboardMenu />
                    </div>
                </div>
                <div className="col-12 col-md-8">
                    <div className="m-1 bg-white p-3 rounded shadow-sm">
                        {loading === false && (
                            <Fragment>
                                <h1>Edit my info</h1>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="first_name">
                                        <Form.Label>First name</Form.Label>
                                        <Form.Control type="text" name="first_name" value={user.first_name} onChange={handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group controlId="last_name">
                                        <Form.Label>Last name</Form.Label>
                                        <Form.Control type="text" name="last_name" value={user.last_name} onChange={handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group controlId="country">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control as="select" onChange={handleInputChange} name="country" value={user.country}>
                                            <option value="0">--Select--</option>
                                            {countries.map((country) => {
                                                return (
                                                    <option key={country.id} value={country.id}>{country.country}</option>
                                                );
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    <Button variant="primary" type="submit">
                                        Save
                                    </Button>
                                    <div id="save-block" style={{display: saved}} className="save-block"><i class="fal fa-check"></i>Saved!</div>
                                </Form>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(UserInfo);