import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants";
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import HowItWorks from './HowItWorks';


const Index = () => {
    const [animals, setAnimals] = useState('');
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState('');
    const [fav, setFav] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const fetchData = async () => {
            const animalData = await axios.get(API_URL + 'index-view/');
            if (localStorage.getItem('token') !== null) {
                const favId = await axios.get(API_URL + 'v1/users/user-fav/', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${localStorage.getItem('token')}`
                    }
                });
                setFav(favId.data);
            }
            setAnimals(animalData.data.animal);
            setTotal(animalData.data.total);
            setLoading(false);
        };
        fetchData();
    }, []);

    const addToFav = (id) => {
        axios.post(API_URL + 'v1/users/add-to-fav/', id, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
            }
        });
        var f = [...fav, id]
        setFav(f);
    }

    const removeFromFav = (id) => {
        axios.post(API_URL + 'v1/users/remove-from-fav/', id, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
            }
        });
        var index = fav.indexOf(id);
        setFav(fav.filter((_, i) => i !== index))
    }

    return (
        <div id="content" className="container-fluid">
            <div className="row header-logo">
                <div class="overlay d-flex">
                    <div className="m-auto">
                        <h1>AdoptPets</h1>
                        <p>working to match people and furry friends</p>
                    </div>
                </div>
            </div>
            <div className="row bg-white py-5">
                <div className="container">
                    <div className="col-12 col-md-8 mx-auto">
                        <h2 className="index-title">What is AdoptPets</h2>
                        <p className="index-block">AdoptPets is a service for meeting potential owners and abandoned animals.</p>
                        <p className="index-block">All the animals here have been rescued from the streets by volunteers and are ready to go to new families.</p>
                        <p className="index-block">We do not accept pets for sale.</p>
                        <p className="index-block__tagline">Adopt don't shop!</p>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    {loading === false && (
                        <Fragment>
                            {animals.map((animal) => {
                                if (animal.photos.length) {
                                    var photo = animal.photos[0].photo;
                                }
                                else {
                                    if (animal.species === 'cat') {
                                        photo = '/static/assets/img/cat_color.png';
                                    }
                                    else if (animal.species === 'dog') {
                                        photo = '/static/assets/img/dog_color.png';
                                    }
                                    else if (animal.species === 'other') {
                                        photo = '/static/assets/img/fish_color.png';
                                    }
                                }
                                let like;
                                if (localStorage.getItem('token') === null) {
                                    like = <div className='like-button' onClick={handleShow}>
                                        <i className="far fa-heart"></i>
                                    </div>;
                                } else {
                                    if (fav.includes(animal.id)) {
                                        like = <div className='like-button' onClick={() => removeFromFav(animal.id)}>
                                            <i className="fas fa-heart"></i>
                                        </div>;
                                    }
                                    else {
                                        like = <div className='like-button' onClick={() => addToFav(animal.id)}>
                                            <i className="far fa-heart"></i>
                                        </div>;
                                    }
                                }

                                return (
                                    <div key={animal.id} className="col-12 col-md my-1 d-flex align-items-stretch">
                                        {like}
                                        <Link key={animal.id} to={"/animals/" + animal.id + "/"}>
                                            <Card key={animal.id} className="h-100">
                                                <Card.Img variant="top" src={photo} />
                                                <Card.Body>
                                                    <Card.Title>{animal.name}</Card.Title>
                                                    <Card.Text>
                                                        {animal.gender}, {animal.age}
                                                    </Card.Text>
                                                    <Card.Text>
                                                        {animal.city}, {animal.country}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Link>
                                    </div>
                                );
                            })}
                            <div className="col-12 col-md my-1 d-flex align-items-stretch">
                                <Link to={"/animals/"}>
                                    <Card className="bg-main h-100">
                                        <Card.Body>
                                            <Card.Img variant="top" className="more-icon" src="/static/assets/img/foot.png" />
                                            <Card.Title className="text-white mt-3">{total} more pets available on AdoptPets</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                            <HowItWorks />
                            <Modal show={show} onHide={handleClose} centered className="modal-block">
                                <Modal.Body closeButton>
                                    <div className="attention">
                                        <p>To add pet to your Favorite List please log in or sign up</p>
                                        <div className="row pt-3">
                                            <div className="col">
                                                <a href="/login/">Log In</a>
                                            </div>
                                            <div className="col">
                                                <a href="/signup/">Sign Up</a>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}

export default withRouter(Index);
