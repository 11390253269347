import React, { useState, useEffect } from 'react';
import { API_URL, URL } from "../../constants";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      window.location.replace(URL + 'dashboard/');
    } else {
      setLoading(false);
    }
  }, []);

  const onSubmit = e => {
    e.preventDefault();

    const user = {
      email: email,
      password: password
    };

    fetch(API_URL + 'v1/users/auth/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    })
      .then(res => res.json())
      .then(data => {
        if (data.key) {
          localStorage.clear();
          localStorage.setItem('token', data.key);
          window.location.replace(URL + 'dashboard/requests/');
        } else {
          setEmail('');
          setPassword('');
          localStorage.clear();
          setErrors(true);
        }
      });
  };

  return (
    <div id="content" className="container mt-5">
      <div className="row">
        <div className="col-12 col-lg-5 col-sm-6 mx-auto bg-white rounded p-3 shadow-sm">
          {loading === false && <h2>Sign in</h2>}
          {errors === true && <h2>Cannot log in with provided credentials</h2>}
          {loading === false && (
            <Form onSubmit={onSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                  placeholder="Enter email" />
              </Form.Group>
              <Form.Group controlId="formBasicPassword1">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password"
                  name="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                  placeholder="Password" />
                  <Form.Text className="text-muted">
                    <a href="/reset/">Forgot password?</a>
                  </Form.Text>
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100">
                Sign in
              </Button>
            </Form>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 mx-auto mt-2">
          <p className="text-center text-small">New to AdoptPets? <a href="/signup/">Sign Up</a></p>
        </div>
      </div>
    </div>
  );
};

export default Login;