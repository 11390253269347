import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { API_URL, URL } from "../../constants";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const PasswordReset = () => {
    let { uid } = useParams();
    let { token } = useParams();
    const [password1, setPass1] = useState('');
    const [password2, setPass2] = useState('');
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState('block');
    const [after, setAfter] = useState('none');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            window.location.replace(URL + 'dashboard/');
        } else {
            setLoading(false);
        }
    }, []);

    const onSubmit = e => {
        e.preventDefault();

        const data = {
            uid: uid,
            token: token,
            new_password1: password1,
            new_password2: password2
        };
        console.log('what we send', data);
        fetch(API_URL + 'v1/users/auth/password/reset/confirm/' + uid + '/' + token + '/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                setMessage(data.detail);
                setForm('none');
                setAfter('block');
            });
    };

    return (
        <div id="content" className="container mt-5">
            <div className="row">
                <div className="col-12 col-lg-5 col-sm-6 mx-auto bg-white rounded p-3 shadow-sm">
                    {loading === false && <h2>Set up new password</h2>}
                    {loading === false && (
                        <Form style={{display: form}} onSubmit={onSubmit}>
                            <Form.Group controlId="formBasicPassword1">
                                <Form.Label>New password</Form.Label>
                                <Form.Control type="password"
                                    name="password1"
                                    value={password1}
                                    onChange={e => setPass1(e.target.value)}
                                    required
                                    placeholder="Password" />
                            </Form.Group>
                            <Form.Text className="text-muted">
                                Password should contains at least 8 symbols, letters and numbers
                            </Form.Text>
                            <Form.Group controlId="formBasicPassword2">
                                <Form.Label>Confirm new password</Form.Label>
                                <Form.Control type="password"
                                    name="password2"
                                    value={password2}
                                    onChange={e => setPass2(e.target.value)}
                                    required
                                    placeholder="Confirm password" />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="w-100">
                                Set
                            </Button>
                        </Form>
                    )}
                    <div style={{display: after}}>
                        <p className="text-center">{message}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordReset;