import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from "react-router";
import DashboardMenu from "./DashboardMenu"
import { API_URL } from "../../constants";
import axios from "axios";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';


const Requests = () => {
    const [requests, setRequests] = useState('');
    const [loading, setLoading] = useState(true);
    const [id, setId] = useState('');

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            window.location.replace('https://adoptpets.eu/login/');
        } else {
            axios.get(API_URL + 'requests/', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(data => {
                setRequests(data.data.requests);
                setId(data.data.user_id);
                setLoading(false);
            });
        }
    }, []);
    
    return (
        <div id="content" className="container mt-5">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="m-1 bg-white p-3">
                        <DashboardMenu />
                    </div>
                </div>
                <div className="col-12 col-md-8">
                    <div className="m-1 bg-white p-3">
                        {loading === false && (
                            <Fragment>
                                <h2>Requests</h2>
                                <Accordion>
                                    {requests !=='' && (
                                        <Fragment>
                                            {requests.map((request, i) => {
                                                return (
                                                    <Card>
                                                        <Card.Header>
                                                        <Accordion.Toggle as={Button} eventKey={i+1}>
                                                            {request.animal.name} / { request.created}
                                                        </Accordion.Toggle>
                                                        </Card.Header>
                                                        <Accordion.Collapse eventKey={i+1}>
                                                        <Card.Body>
                                                            <p>From {request.name} ({request.email}):</p>
                                                            <p>{request.message}</p>
                                                        </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                );
                                            })}
                                        </Fragment>
                                    )}
                                </Accordion>  
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Requests);