import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from "react-router";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Link, useLocation } from "react-router-dom";
import { API_URL } from "../constants";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';


const AnimalList = () => {
  let query = new URLSearchParams(useLocation().search);
  const [animals, setAnimals] = useState('');
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState();
  const [state, setState] = useState({
    sorting: query.get("sort") ? query.get("sort") : '?',
    species: query.get("type") ? query.get("type") : '',
    age: query.get("age") ? query.get("age") : '',
    health: query.get("health") ? query.get("health") : '',
    gender: query.get("gender") ? query.get("gender") : '',
    country: query.get("country") ? query.get("country") : '',
    transfer: query.get("transfer") ? query.get("transfer") : false,
  });
  const history = useHistory();
  const [fav, setFav] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchData = async () => {
      const animalList = await axios.get(API_URL + 'animals/?type=' + state.species + '&gender=' + state.gender + '&age=' + state.age + '&health=' + state.health + '&country=' + state.country + '&transfer=' + state.transfer + '&sort=' + state.sorting);
      if (localStorage.getItem('token') !== null) {
        const favId = await axios.get(API_URL + 'v1/users/user-fav-id/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          }
        });
        setFav(favId.data);
      }
      const countryList = await axios.get(API_URL + 'countrylist/');

      setAnimals(animalList.data);
      setCountries(countryList.data);
      setLoading(false);
    };
    fetchData();
    history.push('?type=' + state.species + '&gender=' + state.gender + '&age=' + state.age + '&health=' + state.health + '&country=' + state.country + '&transfer=' + state.transfer + '&sort=' + state.sorting);
  }, [state, history]);

  const handleInputChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const addToFav = (id) => {
    axios.post(API_URL + 'v1/users/add-to-fav/', id, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    });
    var f = [...fav, id]
    setFav(f);
  }

  const removeFromFav = (id) => {
    axios.post(API_URL + 'v1/users/remove-from-fav/', id, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    });
    var index = fav.indexOf(id);
    setFav(fav.filter((_, i) => i !== index))
  }
  return (
    <div id="content" className="container mt-5">
      <div className="row">
        <div className="col-12 col-lg-3 col-md-4">
          <div className="m-1 bg-main p-3 rounded sort-form">
            <Form>
              <Form.Group controlId="species">
                <Form.Label>Animal species</Form.Label>
                <Form.Control as="select" onChange={handleInputChange} name="species" value={state.species}>
                  <option value="">Any</option>
                  <option value="cat">Cat</option>
                  <option value="dog">Dog</option>
                  <option value="other">Other</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="gender">
                <Form.Label>Animal gender</Form.Label>
                <Form.Control as="select" onChange={handleInputChange} name="gender" value={state.gender}>
                  <option value="">Any</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="age">
                <Form.Label>Age</Form.Label>
                <Form.Control as="select" onChange={handleInputChange} name="age" value={state.age}>
                  <option value="">Any</option>
                  <option value="kid">Less than 1 y.o.</option>
                  <option value="adult">1 - 7 y.o.</option>
                  <option value="senior">More than 7 y.o.</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="health">
                <Form.Label>Health</Form.Label>
                <Form.Control as="select" onChange={handleInputChange} name="health" value={state.health}>
                  <option value="">Any</option>
                  <option value="healthy">Healthy</option>
                  <option value="special">Special needs</option>
                </Form.Control>
              </Form.Group>
              {loading === false && (
                <Fragment>
                  <Form.Group controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Form.Control as="select" onChange={handleInputChange} name="country" value={state.country}>
                      <option value="">Any</option>
                      {countries.map((country) => {
                        return (
                          <option key={country.id} value={country.id}>{country.country}</option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="transfer">
                    <Form.Check type="checkbox" defaultChecked={state.transfer} onChange={() => setState({ ...state, transfer: !state.transfer })} label="Include pets that can be transported abroad" />
                  </Form.Group>
                </Fragment>
              )}
            </Form>
          </div>
        </div>
        <div className="col-12 col-lg-9 col-md-8">
          <Form.Group controlId="sorting">
            <Form.Label>Sort by:</Form.Label>
            <Form.Control as="select" onChange={handleInputChange} name="sorting" value={state.sorting}>
              <option value="?">Randomly</option>
              <option value="-id">Newest first</option>
              <option value="id">Oldest first</option>
            </Form.Control>
          </Form.Group>
          {loading === false && (
            <Fragment>
              <div className="row">
                {animals.map((animal) => {
                  if (animal.photos.length) {
                    var photo = animal.photos[0].photo;
                  }
                  else {
                    if (animal.species === 'cat') {
                      photo = '/static/assets/img/cat_color.png';
                    }
                    else if (animal.species === 'dog') {
                      photo = '/static/assets/img/dog_color.png';
                    }
                    else if (animal.species === 'other') {
                      photo = '/static/assets/img/fish_color.png';
                    }
                  }
                  let like;
                  if (localStorage.getItem('token') === null) {
                    like = <div className='like-button' onClick={handleShow}>
                      <i className="far fa-heart"></i>
                    </div>;
                  } else {
                    if (fav.includes(animal.id)) {
                      like = <div className='like-button' onClick={() => removeFromFav(animal.id)}>
                        <i className="fas fa-heart"></i>
                      </div>;
                    }
                    else {
                      like = <div className='like-button' onClick={() => addToFav(animal.id)}>
                        <i className="far fa-heart"></i>
                      </div>;
                    }
                  }
                  return (
                    <div key={animal.id} className="col-12 col-lg-4 col-sm-6 my-2 d-flex align-items-stretch">
                      {like}
                      <Link key={animal.id} to={"/animals/" + animal.id + "/"}>
                        <Card key={animal.id} className="h-100">
                          <Card.Img variant="top" src={photo} />
                          <Card.Body>
                            <Card.Title>{animal.name}</Card.Title>
                            <Card.Text>
                              {animal.gender}, {animal.age}
                            </Card.Text>
                            <Card.Text>
                              {animal.city}, {animal.country}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Link>
                    </div>
                  );
                })}
              </div>
              <Modal show={show} onHide={handleClose} centered className="modal-block">
                <Modal.Body closeButton>
                  <div className="attention">
                    <p>To add pet to your Favorite List please log in or sign up</p>
                    <div className="row pt-3">
                      <div className="col">
                        <a href="/login/">Log In</a>
                      </div>
                      <div className="col">
                        <a href="/signup/">Sign Up</a>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(AnimalList);