import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Animal from "./components/Animal";
import Favorite from "./components/dashboard/Favorite";
import OrgInfo from "./components/dashboard/OrgInfo";
import UserInfo from "./components/dashboard/UserInfo";
import OrgAnimals from "./components/dashboard/OrgAnimals";
import AnimalList from "./components/AnimalList";
import Header from "./components/Header";
import Index from "./components/Index";
import Donate from "./components/Donate";
import Requests from "./components/dashboard/Requests";
import Page from "./components/Page";
import Dashboard from "./components/dashboard/Dashboard";
import AnimalEdit from "./components/dashboard/AnimalEdit";
import Login from './components/auth/Login'; 
import Signup from './components/auth/Signup';
import Logout from './components/auth/Logout';
import Reset from './components/auth/Reset';
import PasswordReset from './components/auth/PasswordReset';


class App extends Component {
  
  render() {
    return (
      <Router>
        <Header />
        {/* в это место вставится компонент, соответствующий тому path, который был нажат */}
        <Switch>
          <Route exact path="/" children={<Index />} />
          <Route path="/animals/:id/" children={<Animal />} />
          <Route path="/animals/" children={<AnimalList type=""/>} />
          <Route path='/login/' component={Login} exact />
          <Route path='/signup/' component={Signup} exact />
          <Route path='/reset/' component={Reset} exact />
          <Route path='/donate/' children={<Donate />} exact />
          <Route path="/dashboard/requests/" children={<Requests/>} />
          <Route path="/dashboard/fav/" children={<Favorite/>} />
          <Route path="/dashboard/orginfo/" children={<OrgInfo/>} />
          <Route path="/dashboard/userinfo/" children={<UserInfo/>} />
          <Route path="/dashboard/animals/:id/edit/" children={<AnimalEdit type="edit" />} />
          <Route path="/dashboard/animals/" children={<OrgAnimals/>} />
          <Route path="/dashboard/create/" children={<AnimalEdit  type="create" />} />
          <Route path="/dashboard/" children={<Dashboard/>} />
          <Route path='/:slug/' children={<Page />} exact />
          <Route path='/reset/confirm/:uid/:token/' children={<PasswordReset />}/>
        </Switch>
      </Router>
    );
  }
}
export default App;