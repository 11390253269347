import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from "react-router-dom";
import { withRouter } from "react-router";
import { API_URL } from "../constants";
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import parse from 'html-react-parser';

const Page = () => {
    let { slug } = useParams();
    const [page, setPage] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const pageData = await axios.get(API_URL + 'v1/pages/' + slug + '/');
            setPage(pageData.data);
            setLoading(false);
        }
        fetchData();  
    }, [slug]);

    return (
        <Container id="content" className="mt-5">
            <Row>
                <Col className="col-12 col-md-8 mx-auto">
                {loading === false && (
                    <Fragment>
                        <h3 className="page-title">{page.title}</h3>
                        {parse(page.content)}
                    </Fragment>
                )} 
                </Col>
            </Row>
        </Container>

    )
}

export default withRouter(Page);