import React, { Component } from "react";
import Navigation from "./Navbar";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';


class Header extends Component {

  state = {
    show: false
  };

  handleClose = e => {
    this.setState({
        show: false,
    });
  };
  handleShow = e => {
    this.setState({
        show: true
    });
  };

  render() {
    return (
      <header>
        <Container fluid className="bg-white">
          <Container>
            <Row>
              <Col className="align-self-center ua">
                <a className="logo-block" href="/">
                  <img className="logo" src="/static/assets/img/logo.png" alt=""/>
                  <span className="title">AdoptPets</span>
                </a>
              </Col>
              <div className="ml-auto d-none align-items-center login-block d-md-flex">
                <Navigation />
              </div>
            </Row>
          </Container>
        </Container>
        <Container fluid className="bg-main">
          <Container>
            <Row>
              <Navbar className="navigation col" expand="md" variant="dark">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav.Link href="/animals/"><img className="icons" src="/static/assets/img/pets.png" alt=""/>All animals</Nav.Link>
                  <Nav.Link href="/animals/?type=cat"><img className="icons" src="/static/assets/img/cat.png" alt=""/>All cats</Nav.Link>
                  <Nav.Link href="/animals/?type=dog"><img className="icons" src="/static/assets/img/dog.png" alt=""/>All dogs</Nav.Link>
                  <Nav.Link href="/animals/?type=other"><img className="icons" src="/static/assets/img/fish.png" alt=""/>Other animals</Nav.Link>
                </Navbar.Collapse>
              </Navbar>
              <div className="ml-auto d-block align-items-center login-block d-md-none">
                <Navigation />
              </div>
            </Row>
          </Container>
        </Container>
      </header>
      
    );
  }
}

export default Header;