import React from 'react';
import { withRouter } from "react-router";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const HowItWorks = () => {
    return (
        <Container>
            <Row className="mt-5">
                <Col xs={12} lg={8} className="mx-auto">
                    <Row className="mb-3">
                        <Col>
                            <h2 className="index-title">How It Works</h2>
                            <p className="hint">(perfectly simple)</p>
                        </Col>
                    </Row>
                    <Row className="align-items-center my-md-0 my-4">
                        <Col xs={12} md={6}>
                            <div className="step">1</div>
                            <h5 className="how-title">Add new animal</h5>
                            <p className="how-text">Volunteers and shelters describe their animals on AdoptPets, so pets become available for search</p>
                        </Col>
                        <Col xs={12} md={6}>
                            <img className="w-100" src="/static/assets/img/step_1.png" alt=""/>
                        </Col>
                    </Row>
                    <Row className="align-items-center flex-md-row-reverse my-md-0 my-4">
                        <Col xs={12} md={6}>
                            <div className="step">2</div>
                            <h5 className="how-title">Find a pet</h5>
                            <p className="how-text">People who want to adopt a pet are looking for a suitable animal in AdoptPets' database</p>
                        </Col>
                        <Col xs={12} md={6}>
                            <img className="w-100" src="/static/assets/img/step_2.png" alt=""/> 
                        </Col>
                    </Row>
                    <Row className="align-items-center my-md-0 my-4">
                        <Col xs={12} md={6}>
                            <div className="step">3</div>
                            <h5 className="how-title">Is it a match?</h5>
                            <p className="how-text">When potential owners fall in love with a pet they send request to a volunteer (or shelter) to get to know each other better</p>
                        </Col>
                        <Col xs={12} md={6}>
                            <img className="w-100" src="/static/assets/img/step_3.png" alt=""/>
                        </Col>
                    </Row>
                    <Row className="align-items-center flex-md-row-reverse my-md-0 my-4">
                        <Col xs={12} md={6}>
                            <div className="step">4</div>
                            <h5 className="how-title">Adoption!</h5>
                            <p className="how-text">If both sides decide this is a match pet is going to its new home and we all have another Happy Adoption Story</p>
                        </Col>
                        <Col xs={12} md={6}>
                            <img className="w-100" src="/static/assets/img/step_4.png" alt=""/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};
  
export default withRouter(HowItWorks);