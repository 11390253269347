import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from "react-router";
import DashboardMenu from "./DashboardMenu";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { API_URL, URL } from "../../constants";
import axios from "axios";


const OrgInfo = () => {
    const [orgz, setOrg] = useState('');
    const [loading, setLoading] = useState(true);
    const [cities, setCity] = useState('');
    const [countries, setCountries] = useState('');
    const [image, setImage] = useState('');
    const [show, setShow] = useState(false);
    const [geotype, setGeotype] = useState('');
    const [saved, setSaved] = useState('none');

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            window.location.replace(URL + 'login/');
        } else {
            const fetchData = async () => {
                const orgData = await axios.get(API_URL + 'v1/orgs/org/', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${localStorage.getItem('token')}`
                    }
                });

                const countryList = await axios.get(API_URL + 'countrylist/');
                if (orgData.data.country) {
                    const cityList = await axios.get(API_URL + 'citylist/' + orgData.data.country + '/');
                    setCity(cityList.data)
                }

                setOrg(orgData.data);
                setCountries(countryList.data);
                setLoading(false);
            };
            fetchData();
        }
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = (gt) => {
        setGeotype({
            ...geotype,
            type: gt,
            id: 'new-' + gt,
        });
        setShow(true);
    }

    const handleInputNewChange = e => {
        setGeotype({
            ...geotype,
            value: e.target.value,
        });
    }
    const handleNewSubmit = e => {
        e.preventDefault();
        if (geotype.type === 'country') {
            const data = {
                country: geotype.value
            };
            axios.post(API_URL + 'country/', data)
                .then(data => {
                    setCountries(data.data);
                    setOrg({
                        ...orgz,
                        country: data.data[data.data.length - 1].id,
                    });
                    setShow(false);
                    setGeotype('');
                })
                .catch(err => {
                    console.error(err);
                });
        }
        if (geotype.type === 'city') {
            const data = {
                country: orgz.country,
                city: geotype.value
            };
            axios.post(API_URL + 'city/', data)
                .then(data => {
                    setCity(data.data);
                    setOrg({
                        ...orgz,
                        city: data.data[data.data.length - 1].id,
                    });
                    setShow(false);
                    setGeotype('');
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }
    const handleInputChange = e => {
        setOrg({
            ...orgz,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === 'country') {
            var id = e.target.value;
            axios.get(API_URL + 'citylist/' + id + '/')
                .then(data => {
                    setCity(data.data);
                })
                .catch(err => {
                    console.error(err);
                });
        }
    };
    let citylist;
    if (cities) {
        citylist = cities.map((city) => {
            return (
                <option key={city.id} value={city.id}>{city.city}</option>
            );
        })
    }
    else {
        citylist = '';
    }
    const handleFileInputChange = async (e) => {
        e.preventDefault();
        await setImage(e.target.files[0]);
    }


    const handleSubmit = e => {
        e.preventDefault();
        var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        if( regexp.test(orgz.site) === false ) {
            orgz.site = '';
        }
        if( regexp.test(orgz.fb) === false ) {
           orgz.fb = '';
        }
        let data = new FormData();
        if (image !== '') {
            data.append("image", image);
        }
        data.append("id", orgz.id);
        data.append("name", orgz.name);
        data.append("country", orgz.country);
        data.append("city", orgz.city);
        data.append("description", orgz.description);
        data.append("site", orgz.site);
        data.append("fb", orgz.fb);
        data.append("org", orgz.org);

        axios.put(API_URL + 'v1/orgs/org/', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Token ${localStorage.getItem('token')}`
            }
        })
            .then(() => {
                setSaved('inline-block');
                setTimeout(function () {
                    setSaved('none');
                }, 1000);
            })
            .catch(err => {
                console.error(err);
            });
    };
    let status;
    if (orgz.country) {
        status = <Button variant="primary" onClick={() => handleShow('city')}>Add new</Button>;
    }
    else {
        status = <Button variant="primary" disabled>Add new</Button>;
    }
    let logo;
    if (orgz.image !== null) {
        logo = <img src={orgz.image} alt="logo" />
    }
    return (
        <div id="content" className="container mt-5">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="m-1 bg-white p-3 rounded shadow-sm">
                        <DashboardMenu />
                    </div>
                </div>
                <div className="col-12 col-md-8">
                    <div className="m-1 bg-white p-3 rounded shadow-sm">
                        {loading === false && (
                            <Fragment>
                                <h1>Edit organization info</h1>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Organization name</Form.Label>
                                        <Form.Control type="text" name="name" value={orgz.name} onChange={handleInputChange} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.File id="image" label="Logo" name="image"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleFileInputChange} />
                                        {logo}
                                    </Form.Group>
                                    <Form.Group controlId="country">
                                        <Form.Label>Country</Form.Label>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Control inline as="select" onChange={handleInputChange} name="country" value={orgz.country}>
                                                    <option value="0">--Select--</option>
                                                    {countries.map((country) => {
                                                        return (
                                                            <option key={country.id} value={country.id}>{country.country}</option>
                                                        );
                                                    })}
                                                </Form.Control>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Button inline variant="primary" onClick={() => handleShow('country')}>Add new</Button>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group controlId="city">
                                        <Form.Label>City</Form.Label>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Control as="select" onChange={handleInputChange} name="city" value={orgz.city}>
                                                    <option>--Select country first--</option>
                                                    {citylist}
                                                </Form.Control>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                {status}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group controlId="description">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Text className="text-muted">
                                            Shortly describe yourself
                                        </Form.Text>
                                        <Form.Control as="textarea" rows={6} value={orgz.description} onChange={handleInputChange} name="description" />
                                    </Form.Group>
                                    <Form.Group controlId="site">
                                        <Form.Label>Site URL <i>(optional)</i></Form.Label>
                                        <Form.Control type="text" name="site" placeholder="https://example.com" value={orgz.site} onChange={handleInputChange} />
                                    </Form.Group><Form.Group controlId="fb">
                                        <Form.Label>Facebook <i>(optional)</i></Form.Label>
                                        <Form.Control type="text" name="fb" placeholder="https://facebook.com/example" value={orgz.fb} onChange={handleInputChange} />
                                    </Form.Group>
                                    <Button variant="primary" type="submit">
                                        Save
                                    </Button>
                                    <div id="save-block" style={{ display: saved }} className="save-block"><i class="fal fa-check"></i>Saved!</div>
                                </Form>
                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Add new {geotype.type}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form onSubmit={handleNewSubmit}>
                                            <Form.Group controlId={geotype.id}>
                                                <Form.Control type="text" name={geotype.id} value={geotype.value} onChange={handleInputNewChange} />
                                            </Form.Group>
                                            <Button variant="primary" type="submit">
                                                Submit
                                            </Button>
                                        </Form>
                                    </Modal.Body>
                                </Modal>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(OrgInfo);