import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from "react-router";
import { API_URL } from "../constants";
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { loadStripe } from "@stripe/stripe-js/pure";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import parse from 'html-react-parser';

const Donate = () => {
    const [page, setPage] = useState('');
    const [loading, setLoading] = useState(true);
    const stripePromise = loadStripe('pk_live_51J0NdmA6EzUPB8TWbo6x5yzVobM1GgDIdMeDG86KHLEs14TegVcJ4UhQr7mxMne9kiWLHUMESHKQvHIIxXzBGPBs00eejQi7ss');
    const [amount, setAmount] = useState('');
    const [mode, setMode] = useState('subscription');
    const [price, setPrice] = useState('price_1J7iqLA6EzUPB8TWNnyTYB6u')
    const [payment, setPayment] = useState('none');
    const [subscription, setSubscription] = useState('flex');

    useEffect(() => {
        const fetchData = async () => {
            const pageData = await axios.get(API_URL + 'v1/pages/donate/');
            setPage(pageData.data);
            setLoading(false);
        }
        fetchData();
    }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const stripe = await stripePromise;
        const data = {
            amount: amount,
            mode: mode,
            price: price
        };
        await axios.post(API_URL + 'v1/pages/create-checkout-session/', data)
            .then((data) => {
                return stripe.redirectToCheckout({ sessionId: data.data.sessionId })
            })
            .then((res) => {
                console.log(res);
            });
    };
    const handleInputChange = e => {
        setAmount(e.target.value);
    };

    const handleChange = (m) => {
        document.getElementById(mode).classList.remove("active");
        document.getElementById(m).classList.add("active");
        if (m === 'payment') {
            setSubscription('none');
            setPayment('flex');
        }
        else {
            setPayment('none');
            setSubscription('flex');
        }
        setMode(m);
    }
    const handleSubChange = (id) => {
        document.getElementById(price).classList.remove("active");
        document.getElementById(id).classList.add("active");
        setPrice(id);
    }

    return (
        <Container id="content" className="mt-5">
            <Row>
                <Col className="col-12 col-md-8 mx-auto p-3">
                    {loading === false && (
                        <Fragment>
                            <h3 className="page-title">{page.title}</h3>
                            {parse(page.content)}
                            <Form onSubmit={handleSubmit} className="stripe-form col-12 col-md-6 mx-auto p-3">
                                <InputGroup style={{display: payment}}>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>€</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="text" name="amount" value={amount} onChange={handleInputChange} placeholder="20" />
                                </InputGroup>
                                <Row style={{display: subscription}} className="mx-0 mb-2">
                                    <Col onClick={() => handleSubChange('price_1J7ip8A6EzUPB8TWjNFUC7EG')} id="price_1J7ip8A6EzUPB8TWjNFUC7EG" className="donate col">€5</Col>
                                    <Col onClick={() => handleSubChange('price_1J7iqLA6EzUPB8TWNnyTYB6u')} id="price_1J7iqLA6EzUPB8TWNnyTYB6u" className="donate col active">€10</Col>
                                    <Col onClick={() => handleSubChange('price_1J7iqjA6EzUPB8TW3CLNeOer')} id="price_1J7iqjA6EzUPB8TW3CLNeOer" className="donate col">€20</Col>
                                </Row>
                                <Row className="mx-0 my-2">
                                    <Col onClick={() => handleChange('subscription')} id="subscription" className="col active">Monthly</Col>
                                    <Col onClick={() => handleChange('payment')} id="payment" className="col">One-time</Col>
                                </Row>
                                <Button variant="primary" type="submit">
                                    Support project<i class="ml-2 far fa-long-arrow-right"></i>
                                </Button>
                            </Form>
                        </Fragment>
                    )}
                </Col>
            </Row>
        </Container>

    )
}


export default withRouter(Donate);