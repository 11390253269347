import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'

const Footer = () => {
  return (
    <Container fluid className="footer bg-white border-top mt-5 p-md-5 py-4 px-2">
      <Container>
        <Row>
          <Col className="footer-links text-center mb-3">
            <a className="mx-2" href="/policy/">Policy</a>
            <a className="mx-2" href="/about/">About project</a>
            <a className="mx-2" href="/donate/">Support project</a>
          </Col>
        </Row>
        <Row>
          <Col className="copyrights text-center">
            <p>AdoptPets &copy; 2022 | <a href="mailto:info@adoptpets.eu">info@adoptpets.eu</a></p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Footer;