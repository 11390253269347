import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from "react-router";
import DashboardMenu from "./DashboardMenu";
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import { API_URL } from "../../constants";
import axios from "axios";


const Favorite = () => {
    const [animals, setAnimals] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            window.location.replace('https://adoptpets.eu/login/');
        } else {

            axios.get(API_URL + 'v1/users/user-fav/', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
                .then(data => {
                    setAnimals(data.data);
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                });

        }
    }, []);

    return (
        <div id="content" className="container mt-5">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="m-1 bg-white p-3 rounded shadow-sm">
                        <DashboardMenu />
                    </div>
                </div>
                <div className="col-12 col-md-8">
                    <div className="m-1 bg-white p-3 rounded shadow-sm">
                        {loading === false && (
                            <Fragment>
                                <h1>Favorite pets</h1>
                                <div className="row">
                                    {animals.map((animal) => {
                                        if (animal.photos.length) {
                                            var photo = animal.photos[0].photo;
                                        }
                                        else {
                                            if (animal.species === 'cat') {
                                                photo = '/assets/img/cat.png';
                                            }
                                            else if (animal.species === 'dog') {
                                                photo = '/assets/img/dog.png';
                                            }
                                            else if (animal.species === 'other') {
                                                photo = '/assets/img/unicorn.png';
                                            }
                                        }
                                        return (
                                            <div key={animal.id} className="col-12 col-lg-4 col-md-6 my-1">
                                                <Link key={animal.id} to={"/animals/" + animal.id + "/"}>
                                                    <Card key={animal.id}>
                                                        <Card.Img variant="top" src={photo} />
                                                        <Card.Body>
                                                            <Card.Title>{animal.name}</Card.Title>
                                                            <Card.Text>
                                                                {animal.gender}, {animal.age}
                                                            </Card.Text>
                                                            <Card.Text>
                                                                {animal.city}, {animal.country}
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            </div>
                                        );
                                    })}
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Favorite);